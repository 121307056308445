import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { i18n } from '@pija-ab/i18n-react-system';

import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';
import { getUser, getOrganization } from 'src/store/utilities';

import SidemenuView from './Sidemenu';
import { Dispatch } from 'redux';
import { Organization } from 'src/types/common';

type Props = {
  history: {
    push: (val: string) => void;
  };
  logoutUser: () => void;
  organization: Organization;
  childOrganizations: Organization[];
  user?: {
    role: string;
    organizationId?: string;
  };
  loadOrganization: ({ organizationId }: { organizationId: string }) => void;
  loadAllOrganizations: () => void;
  loadChildOrganizations: ({
    organizationId,
  }: {
    organizationId: string;
  }) => void;
  parentOrg: Organization | null;
  allOrgsFetched: boolean;
};

export type Lang = {
  name: string;
  nativeName: string;
  code: string;
};

export type LangData = {
  [key: string]: {
    name: string;
    nativeName: string;
  };
};

const SideMenuContainer = (props: Props) => {
  const [langs, setLangs] = useState<Lang[] | null>(null);
  const [curLngIndex, setCurLngIndex] = useState<number | null>(null);

  const [error, setError] = useState(false);
  const logout = () => {
    const { logoutUser, history } = props;
    logoutUser();
    history.push('/');
  };

  useEffect(() => {
    i18n.services.backendConnector.backend.getLanguages(
      (err: unknown, data: LangData) => {
        if (err) {
          setError(true);
          return;
        }

        const lngs: Lang[] = Object.keys(data).map(key => ({
          code: key,
          name: data[key].name,
          nativeName: data[key].nativeName,
        }));
        if (process.env.NODE_ENV === 'development') {
          lngs.push({
            code: 'cimode',
            name: 'Translator Mode',
            nativeName: 'cimode',
          });
        }

        setLangs(lngs);
        if (curLngIndex === null) {
          const index = lngs.findIndex(l => l.code === i18n.language);
          setCurLngIndex(index);
        }
      },
    );
  }, []);

  const changeLang = (val: unknown) => {
    if (!langs || !i18n.language || !val) return;

    i18n.changeLanguage(val);
    const index = langs.findIndex(l => l.code === val);
    setCurLngIndex(index);
  };

  const {
    user,
    organization,
    childOrganizations,
    loadOrganization,
    loadChildOrganizations,
    loadAllOrganizations,
    parentOrg,
    allOrgsFetched,
  } = props;

  if (
    user &&
    user.role === 'hr' &&
    user.organizationId != null &&
    !organization &&
    loadOrganization
  ) {
    loadOrganization({ organizationId: user.organizationId });
    return null;
  }

  if (
    user &&
    user.role === 'customer_admin' &&
    user.organizationId != null &&
    loadAllOrganizations &&
    childOrganizations.length === 0 &&
    !allOrgsFetched
  ) {
    /**
     * Load all organizations when you are a customer admin
     * this is so that we can show the adjecent organizations in the side menu + the parent organization
     * loading all in this case will just be the parent organization and it's children if you're a customer admin
     */
    loadAllOrganizations();
    return null;
  }

  useEffect(() => {
    if (organization?.parentOrganizationId && !parentOrg) {
      /**
       * Load all organizations when you are a customer admin
       * this is so that we can show the adjecent organizations in the side menu + the parent organization
       * loading all in this case will just be the parent organization and it's children if you're a customer admin
       */
      loadOrganization({ organizationId: organization.parentOrganizationId });
    }
  }, [organization?.parentOrganizationId, parentOrg]);

  if (
    user &&
    user.role === 'administrator' &&
    organization &&
    organization.parentOrganizationId &&
    childOrganizations.length === 0
  ) {
    /**
     * Load parent organization and children of that parent organization
     * this is so that we can show the adjecent organizations in the side menu + the parent organization
     */
    loadChildOrganizations({
      organizationId: organization.parentOrganizationId,
    });
    return null;
  }

  return (
    <SidemenuView
      {...props}
      logout={logout}
      curLngIndex={curLngIndex}
      langs={langs}
      onChange={changeLang}
      langError={error}
      childOrganizations={childOrganizations}
      parentOrganization={parentOrg || organization}
    />
  );
};

// @ts-expect-error State has no type yet
const mapStateToProps = (state, ownProps) => {
  const user = getUser(state, state.userData.user).entity;
  let organization = {} as Organization;
  let childOrganizations = [] as Organization[];
  let parentOrg = null as Organization | null;
  let allOrgsFetched = false as boolean;

  const routeOrganizationId = ownProps.location.pathname.split('/')[2];

  if (user) {
    if (user.role === 'hr') {
      organization = getOrganization(state, user.organizationId).entity;
    }
    if (user.role === 'customer_admin') {
      if (routeOrganizationId) {
        organization = getOrganization(state, routeOrganizationId).entity;
      } else {
        organization = getOrganization(state, user.organizationId).entity;
      }
      const allOrgs = state.organizations.all.map((org: string) => {
        return getOrganization(state, org).entity;
      });
      allOrgsFetched = allOrgs.length > 0;
      if (allOrgs.length > 1) {
        childOrganizations = allOrgs.filter(
          (org: Organization) =>
            org.parentOrganizationId === user.organizationId &&
            org.archived === false,
        );
        parentOrg = allOrgs.find(
          (org: Organization) =>
            org.id === user.organizationId && org.archived === false,
        );
      }
    }
    if (user.role === 'administrator') {
      if (routeOrganizationId) {
        organization = getOrganization(state, routeOrganizationId).entity;
        if (organization) {
          let orgId = routeOrganizationId;
          if (organization.parentOrganizationId) {
            orgId = organization.parentOrganizationId;
            parentOrg = getOrganization(state, orgId).entity;
          }
          const childOrganizationIds =
            state.organizations.childOrganizationsByParentId[orgId];

          if (childOrganizationIds) {
            childOrganizations = childOrganizationIds
              .map((childOrgId: string) => {
                return getOrganization(state, childOrgId).entity;
              })
              .filter((org: Organization) => org.archived === false);
          }
        }
      }
    }
  }

  return { user, organization, childOrganizations, parentOrg, allOrgsFetched };
};

const mapDispatchProps = (dispatch: Dispatch) => ({
  logoutUser: wrapAPIDispatch(dispatch, actions.logoutUser),
  loadOrganization: wrapAPIDispatch(dispatch, actions.loadOrganization),
  loadAllOrganizations: wrapAPIDispatch(dispatch, actions.loadAllOrganizations),
  loadChildOrganizations: wrapAPIDispatch(
    dispatch,
    actions.loadChildOrganizations,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchProps)(SideMenuContainer),
);
